<template>
  <Header />
  <Footer />
  <div class="container-margin">
    <div>
      <div class="flex column">
        <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
          {{ $t("Home.catalog") }}
        </h6>
        <div class="flex justify-start items-center q-ml-sm">
          <q-input
            rounded
            outlined
            class="q-pa-sm flex justify-center items-center text-center"
            type="text"
            v-model="search"
            :placeholder="$t('BuyingProcess.products.search-product')"
          >
            <q-icon
              class="q-pa-none q-ma-none icon-white"
              name="search"
              size="24px"
              style="position: relative; top: 7px"
            />
          </q-input>
        </div>
      </div>
      <div class="flex justify-start">
        <select @change="onChange()" v-model="value" class="q-ml-md q-pa-sm">
          <option value="all">
            {{ $t("BuyingProcess.products.show-all") }}
          </option>
          <option :value="null" disabled hidden>
            {{ $t("BuyingProcess.products.choose-option") }}
          </option>
          <option value="stock">
            {{ $t("BuyingProcess.products.show-stock") }}
          </option>
          <option value="points">
            {{ $t("BuyingProcess.products.available-points") }}
          </option>
          <!-- <option value="orderAscending">Ordenar por precio ascendente</option>
          <option value="orderDescending">
            Ordenar por precio descendente
          </option> -->
        </select>
      </div>
      <!-- TODOS -->
      <div
        v-if="products.length > 0"
        class="row q-ma-md products-container"
        v-show="allProducts"
      >
        <div
          v-for="product in filteredList"
          :key="product.id"
          class="list-product cursor-pointer"
          id="list-products"
          v-show="product.visible"
        >
          <img
            class="rounded-borders cursor-pointer"
            :src="
              product.images.length > 0
                ? publicPath + '/storage/' + product.images[0].image
                : defaultImage
            "
            :alt="product.name"
            loading="lazy"
            clickable
            @click="showItem(product)"
            :disabled="points.points.points >= product.value ? disabled : 0"
          />
          <div class="text-subtitle1 text-left q-ml-md">
            {{ product.name }}
          </div>
          <div class="text-subtitle2 text-left q-ml-md">
            {{ product.value }} {{ settings.currency_abbreviation }}
          </div>
        </div>
      </div>
      <template v-if="filteredList.length > 0"> </template>
    </div>

    <!-- CON STOCK -->

    <div
      v-if="products.length > 0"
      class="row q-ma-md products-container"
      v-show="stockProducts"
    >
      <div
        v-for="product in filteredList"
        :key="product.id"
        class="list-product cursor-pointer"
        id="list-products"
        v-show="product.stock > 0"
      >
        <img
          class="rounded-borders cursor-pointer"
          :src="
            product.images.length > 0
              ? publicPath + '/storage/' + product.images[0].image
              : defaultImage
          "
          :alt="product.name"
          loading="lazy"
          clickable
          @click="showItem(product)"
          :disabled="points.points.points > product.value ? disabled : 0"
        />
        <div class="text-subtitle1 text-left q-ml-md">
          {{ product.name }}
        </div>
        <div class="text-subtitle2 text-left q-ml-md">
          {{ product.value }} {{ settings.currency_abbreviation }}
        </div>
      </div>
    </div>
    <template v-if="filteredList.length > 0"> </template>

    <!-- Puntos disponibles -->
    <div
      v-if="products.length > 0"
      class="row q-ma-md products-container"
      v-show="pointsProducts"
    >
      <div
        v-for="product in filteredList"
        :key="product.id"
        class="list-product cursor-pointer"
        id="list-products"
        v-show="points.points.points > product.value"
      >
        <img
          class="rounded-borders cursor-pointer"
          :src="
            product.images.length > 0
              ? publicPath + '/storage/' + product.images[0].image
              : defaultImage
          "
          :alt="product.name"
          loading="lazy"
          clickable
          @click="showItem(product)"
          :disabled="points.points.points > product.value ? disabled : 0"
        />
        <div class="text-subtitle1 text-left q-ml-md">
          {{ product.name }}
        </div>
        <div class="text-subtitle2 text-left q-ml-md">
          {{ product.value }} {{ settings.currency_abbreviation }}
        </div>
      </div>
    </div>

    <!-- Ordenar ascdendente -->
    <!-- <div
      v-if="products.length > 0"
      class="row q-ma-md products-container"
      v-show="orderAscending"
    >
      <div
        v-for="product in filteredList.sort(function (a, b) {
          return parseFloat(a.value) - parseFloat(b.value);
        })"
        :key="product.id"
        class="list-product cursor-pointer"
        id="list-products"
        v-show="orderAscending"
      >
        <img
          class="rounded-borders cursor-pointer"
          :src="
            product.images.length > 0
              ? publicPath + '/storage/' + product.images[0].image
              : defaultImage
          "
          :alt="product.name"
          loading="lazy"
          clickable
          @click="showItem(product)"
          :disabled="points.points.points > product.value ? disabled : 0"
        />
        <div class="text-subtitle1 text-left q-ml-md">
          {{ product.name }}
        </div>
        <div class="text-subtitle2 text-left q-ml-md">
          {{ product.value }} {{ settings.currency_abbreviation }}
        </div>
      </div>
    </div>

    Ordenar desc 
   <div
      v-if="products.length > 0"
      class="row q-ma-md products-container"
      v-show="orderDescending"
    >
      <div
        v-for="product in filteredList.sort(function (b, a) {
          return parseFloat(a.value) - parseFloat(b.value);
        })"
        :key="product.id"
        class="list-product cursor-pointer"
        id="list-products"
        v-show="orderDescending"
      >
        <img
          class="rounded-borders cursor-pointer"
          :src="
            product.images.length > 0
              ? publicPath + '/storage/' + product.images[0].image
              : defaultImage
          "
          :alt="product.name"
          loading="lazy"
          clickable
          @click="showItem(product)"
          :disabled="points.points.points > product.value ? disabled : 0"
        />
        <div class="text-subtitle1 text-left q-ml-md">
          {{ product.name }}
        </div>
        <div class="text-subtitle2 text-left q-ml-md">
          {{ product.value }} {{ settings.currency_abbreviation }}
        </div>
      </div>
    </div> --> 
    <template v-if="filteredList.length > 0"> </template>
    <div v-else>{{ $t("General.no-data") }}</div>

    <!-- Dialog que muestra ficha producto -->
    <div>
      <ProductPage v-model="dialog" :product="products.id" :points="points" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions } from "vuex";
import settings from "@/mixins/settings";

const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

const ProductPage = defineAsyncComponent(() =>
  import("../components/ProductPage.vue")
);

export default {
  mixins: [settings],

  components: {
    Header,
    Footer,
    ProductPage,
  },
  data() {
    return {
      dialog: false,
      defaultImage: this.defaultImageApp,
      disabled: null,
      search: "",
      allProducts: true,
      stockProducts: false,
      pointsProducts: false,
      // orderAscending: false,
      // orderDescending: false,
      value: null,
    };
  },
  computed: {
    products() {
      return this.$store.state.products.products;
    },
    points() {
      return this.$store.state.points.points;
    },
    filteredList() {
      return this.products.filter((product) => {
        return product.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    
  },

  methods: {
    ...mapActions("products", ["getProducts"]),
    ...mapActions("points", ["getPoints"]),
    
    onChange() {
      if (this.value == "stock") {
        this.allProducts = false;
        this.stockProducts = true;
        this.pointsProducts = false;
        this.orderAscending = false;
        this.orderDescending = false;
      }

      if (this.value == "all") {
        this.allProducts = true;
        this.stockProducts = false;
        this.pointsProducts = false;
        this.orderAscending = false;
        this.orderDescending = false;
      }

      if (this.value == "points") {
        this.allProducts = false;
        this.stockProducts = false;
        this.pointsProducts = true;
        this.orderAscending = false;
        this.orderDescending = false;
      }

      // if (this.value == "orderAscending") {
      //   this.orderAscending = true;
      //   this.allProducts = false;
      //   this.stockProducts = false;
      //   this.pointsProducts = false;
      //   this.orderDescending = false;
      // }

      // if (this.value == "orderDescending") {
      //   this.orderAscending = false;
      //   this.allProducts = false;
      //   this.stockProducts = false;
      //   this.pointsProducts = false;
      //   this.orderDescending = true;
      // }
    },

    showItem(id) {
      this.dialog = true;
      this.products.id = id;
    },
  },

  setup() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    };
  },

  mounted() {
    this.getProducts();
    // this.getPoints();
    // this.hola();
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";
.products-container {
  max-width: 646px;
  overflow: scroll;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
}

@media only screen and (max-width: 765px) {
  .products-container {
    justify-content: space-around;
  }
}

.container-margin {
  margin-bottom: 100px;
}

select {
  border-radius: 25px 25px 25px 0px;
  height: 40px;
  width: 215px;
  border-color: #c7c7c7;
  color: #909090;
}

.q-img,
img {
  border-radius: 25px 25px 25px 0px;
  height: 137px;
  margin: 0.5rem;
  object-fit: contain;
  width: 130px;
}

@media only screen and (min-width: 600px) {
  .q-img {
    width: 160px;
    height: 177px;
  }

  .text-subtitle1 {
    max-width: 120px;
  }
}

.text-subtitle1 {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  max-width: 140px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1rem;
}

.text-subtitle2 {
  font-size: 14px;
  font-weight: 400;
  color: $gray2;
}

.q-field {
  border-color: v-bind("settings.color_primary") !important;
}

// .list-product:nth-child(even) {
//   margin-top: 20px;
// }
</style>
